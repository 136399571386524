import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

import {MenuItem, NewMenuService, RootTreeItem} from '@app/core/services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {
  menu$: Observable<RootTreeItem[]>;
  loading = false;
  readonly searchControl = new FormControl(null);
  filteredMenuItems: MenuItem[];
  isShowTree = true;

  private menuItems: MenuItem[] = [];
  private readonly destroy$ = new Subject<boolean>();

  constructor(private $menu: NewMenuService) {}

  ngOnInit() {
    this.loading = true;
    this.menu$ = this.$menu.getTree().pipe(
      finalize(() => {
        this.loading = false;
      }),
      tap(menu => {
        menu.forEach(root => {
          root.tree.forEach(treeItem => {
            this.menuItems = [...this.menuItems, ...treeItem.items, treeItem.section];
          });
        });
      }),
    );

    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.isShowTree = value.length < 3;

      if (!this.isShowTree) {
        this.filteredMenuItems = this.filterMenuItems(this.menuItems, value);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Фильтрую список элементов меню
   * @param {MenuItem[]} items - пункты меню
   * @param {string} value - срока поиска
   * @return {MenuItem[]} - новый массив с отфильтрованными пунктами меню
   */
  private filterMenuItems(items: MenuItem[], value: string): MenuItem[] {
    return items.filter(
      item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );
  }
}
