import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TableColumnType, TableConfigType} from '@app/shared/services/table.service';
import {NotificationsService} from '@core/services/notifications.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {concat, EMPTY, forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {FetcherService} from '@core/services/fetcher.service';
import {DomSanitizer} from '@angular/platform-browser';
import {jsonValidator} from '@app/shared/services/json-validator';
import {maxDateValidator, minDateValidator} from '@app/shared/services/date-validator';

@Component({
  selector: 'app-form-dialog',
  templateUrl: 'form-dialog.component.html',
  styleUrls: ['form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDialogComponent implements OnInit, OnDestroy {
  _row: any;
  form: FormGroup;
  isDuplicate: boolean;
  visibleFields: TableColumnType[];
  fd = new FormData();
  iconPreview: any;
  isEditMode = false;
  isCopyMode = false;

  uploading: boolean;

  confirmed: boolean;
  confirmationVisible: boolean;

  dragging = 0;
  fileDropAccess: boolean;
  @HostListener('dragenter', ['$event'])
  fileDragenter = (ev: any): void => {
    ev.preventDefault();
    ev.stopPropagation();
    this.dragging++;
    ev.dataTransfer.effectAllowed = 'copy';
    this.fileDropAccess = true;
  };

  @HostListener('dragleave', ['$event'])
  fileDragleave = (ev: any): void => {
    ev.preventDefault();
    ev.stopPropagation();
    this.dragging--;
    if (this.dragging === 0) {
      this.fileDropAccess = false;
    }
  };

  @HostListener('dragover', ['$event'])
  preventDrop = function (ev: any) {
    ev.preventDefault();
  };

  private isFormUpdated = false;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      config: TableConfigType;
      slug: string;
      rows: any;
      systemName?: string;
      staticDataFilter?: any;
      copy?: boolean;
      isSearch?: boolean;
    },
    private $notifications: NotificationsService,
    private $fetcher: FetcherService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.isCopyMode = !!this.data.copy;
    this.isEditMode = !!this.data.rows.length;

    const presetKeys = this.data.staticDataFilter
      ? Object.keys(this.data.staticDataFilter)
      : [];
    this.visibleFields = [
      ...this.data.config.columns.filter(
        x => !x.hidden && x.type !== 'fake' && !presetKeys.includes(x.prop),
      ),
    ];

    if (this.data.rows && this.data.rows.length > 0 && this.data.rows.length < 2) {
      this._row = Object.assign({}, this.data.rows[0]);
    } else {
      const initialValues = {};
      this.visibleFields.forEach(col => {
        if (typeof col.default !== 'undefined') {
          initialValues[col.prop] = col.default;
        }
      });
      this._row = Object.assign({}, initialValues);
    }

    // Создаю форму
    const formObject: any = {};
    this.visibleFields.forEach(col => {
      if (col.type === 'json') {
        this._row[col.prop] = JSON.stringify(this._row[col.prop], null, 2);
      }
      if (col.type === 'select' && col.typeProps.entityFilter) {
        col.typeProps.entityName = this._row[col.typeProps.entityFilter];
      }
      if (!col.hidden) {
        formObject[col.prop] = this.getFormControl(col);
      }
    });
    this.form = new FormGroup(formObject);

    // Подписываюсь на изменения
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.isFormUpdated) {
        this.onChanges();
      }
    });

    // Создаю подписки для полей с фильтрами
    this.visibleFields.forEach(col => {
      if (col.type === 'select') {
        if (col.typeProps.filter) {
          col.typeProps.filter$ = this.getSelectFilterParams(col);
        }
        if (col.typeProps.entityFilter) {
          this.form
            .get(col.typeProps.entityFilter)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(value => {
              col.typeProps.entityName = value.name;
            });
        }
        if (col.typeProps.clearOnChange || col.typeProps.putOnChange) {
          this.form
            .get(col.prop)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(value => {
              const toClear = col.typeProps.clearOnChange;
              if (toClear) {
                toClear.forEach(key => {
                  const colToClear = this.data.config.columns.find(c => c.prop === key);
                  if (this.form.get(key)) {
                    if (
                      colToClear &&
                      colToClear.type === 'select' &&
                      colToClear.typeProps.multiple
                    ) {
                      this.form.get(key).setValue([]);
                    } else {
                      this.form.get(key).setValue(null);
                    }
                  }
                });
              }

              const toChange = col.typeProps.putOnChange;
              if (toChange) {
                Object.keys(toChange).forEach(key => {
                  const replacer = toChange[key];
                  if (Array.isArray(replacer)) {
                    const field = this.form.get(key);
                    if (field && field.value !== value) {
                      const newValue = value
                        ? value[replacer[0]].replace(RegExp(replacer[1]), replacer[2])
                        : null;
                      field.setValue(newValue);
                      field.markAsDirty();
                    }
                  } else {
                    const field = this.form.get(key);
                    if (field && field.value !== value) {
                      field.setValue(value ? value[replacer] : null);
                      field.markAsDirty();
                    }
                  }
                });
              }
            });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  dismiss() {
    if (this.form.dirty && !this.data.isSearch) {
      this.confirmationVisible = true;
    } else {
      this.dialogRef.close();
    }
  }

  confirmedDismiss(confirmed: boolean) {
    if (confirmed) {
      this.dialogRef.close();
    } else {
      this.confirmationVisible = false;
    }
  }

  onSelectChange(column: TableColumnType, value: any) {
    if (value && column.typeProps.updateOnChange) {
      column.typeProps.updateOnChange.forEach((fields: any) => {
        const control = this.form.get(fields[0]);
        if (control) {
          if (typeof fields[1] === 'object') {
            const _value = {
              id: value[fields[1].id],
              name: value[fields[1].name],
            };
            control.setValue(_value);
          } else {
            control.setValue(value[fields[1]]);
          }
        }
      });
    }
  }

  onChanges() {
    this.isFormUpdated = true;

    this.visibleFields.forEach(col => {
      const formControl = this.form.get(col.prop);
      const isHasDefaultValue = typeof col.default !== 'undefined';

      if (col.prop === 'dateStart' || col.prop === 'dateEnd') {
        this.form.get(col.prop).setValidators(this.getValidators(col));
      }

      if (col.disabledUntil) {
        if (this.form.get(col.disabledUntil).value) {
          formControl.enable({emitEvent: false});
        } else {
          formControl.disable({emitEvent: false});
          this.form
            .get(col.prop)
            .reset(isHasDefaultValue ? col.default : null, {emitEvent: false});
        }
      }

      if (col.disabledIfExists) {
        if (this.form.get(col.disabledIfExists).value) {
          this.disabledIfExistsForm(col, formControl, isHasDefaultValue);
        } else if (!col.disabledUntil) {
          formControl.enable({emitEvent: false});

          if (!formControl.value) {
            formControl.setValue(isHasDefaultValue ? col.default : null, {
              emitEvent: false,
            });
          }
        }

        this.sbsDisabledIfExistsForm(col, formControl, isHasDefaultValue);
      }

      if (col.requiredCondition) {
        const required = this.getRequiredCompare(col.requiredCondition);
        if (col.required !== required) {
          col.required = required;
          this.form.get(col.prop).setValidators(this.getValidators(col));
        }
      }
    });
  }

  onChangeColor(prop: string, color: string) {
    this.form.controls[prop].markAsDirty();
    this.form.patchValue({[prop]: color}, {emitEvent: true});
  }

  save(manualPatch = false) {
    const config = this.data.config;
    let request: Observable<any>;
    let requestType: 'POST' | 'PUT' = 'POST';

    if (config.icons) {
      const iconConfig = config.icons[0];
      const props = [{}];
      iconConfig.props.forEach(p => {
        // TODO: Используется в единственном справочнике, поэтому берется айдишник из двух выпадашек. Для полей другого типа - доработать
        props[0][p] = this.form.get(p).value.id;
      });
      this.fd.append(iconConfig.propsKey, JSON.stringify(props));
      request = this.$fetcher.postBySlug(
        iconConfig.endPoint,
        this.fd,
        iconConfig.apiPrefix,
        this.data.systemName,
      );
    } else if (!manualPatch && (this.data.rows.length === 0 || this.data.copy)) {
      if (this.data.rows.length === 0) {
        const data = Object.assign(
          {},
          this.data.staticDataFilter || {},
          this.getDirtyValues(false),
        );

        request = this.$fetcher.postBySlug(
          this.data.slug,
          [data],
          config.apiPrefix,
          config.systemName || this.data.systemName,
        );
      } else {
        request = forkJoin(
          this.data.rows.map(row => {
            delete row.id;
            return this.$fetcher.postBySlug(
              this.data.slug,
              [
                Object.assign(
                  row,
                  this.data.staticDataFilter || {},
                  this.getDirtyValues(false),
                ),
              ],
              config.apiPrefix,
              config.systemName || this.data.systemName,
            );
          }),
        );
      }
    } else {
      request = forkJoin(
        this.data.rows.map(row => {
          return this.$fetcher.putBySlug(
            this.data.slug,
            row.id,
            this.getDirtyValues(),
            config.apiPrefix,
            config.systemName || this.data.systemName,
          );
        }),
      );
      requestType = 'PUT';
    }

    request
      .pipe(
        takeUntil(this.destroy$),
        catchError(response => {
          throwError(
            this.$notifications.showHttpError(
              response,
              requestType,
              this.data.config.title,
            ),
          );
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  sendFilters() {
    this.dialogRef.close({
      fullValues: this.form.value,
      shortValues: this.getDirtyValues(false),
    });
  }

  clearFilters() {
    Object.keys(this.form.controls).forEach(controlName => {
      this.form.get(controlName).setValue(null);
    });
  }

  getSelectFilterParams(column): Observable<any> {
    const filter = column.typeProps.filter;
    if (typeof filter !== 'object') {
      return concat(
        of(this.getSelectFilter(column)),
        this.form
          .get(filter)
          .valueChanges.pipe(
            map(filterValue => this.getSelectFilter(column, filterValue)),
          ),
      );
    } else {
      return of(filter);
    }
  }

  onIconAdd(fileInput: HTMLInputElement, iconConfig: any) {
    if (this.fd.has(iconConfig.fileKey)) {
      this.fd.delete(iconConfig.fileKey);
    }
    if (fileInput.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.iconPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
          e.target.result.toString(),
        );

        this.cdr.markForCheck();
      };
      reader.readAsDataURL(fileInput.files[0]);
      this.fd.append(iconConfig.fileKey, fileInput.files[0]);
    }
  }

  private getSelectFilter(column, value?) {
    const filter = column.typeProps.filter;
    const filterValue = value || this.form.get(filter).value;
    if (filterValue) {
      if (column.typeProps.search) {
        return {
          linkTable: filter.replace('Id', ''),
          linkTableId: Array.isArray(filterValue)
            ? filterValue.map(x => x.id).join(',')
            : filterValue.id || null,
        };
      } else {
        return {
          [filter]: filterValue ? filterValue.id : null,
        };
      }
    } else {
      return {};
    }
  }

  private getFormControl(col: TableColumnType): FormControl {
    const disabled =
      col.disabled ||
      (col.disabledEdit && this.isEditMode && !this.isCopyMode) ||
      (col.disabledUntil && !this._row[col.disabledUntil]) ||
      (col.disabledIfExists && this._row[col.disabledIfExists]) ||
      (this.data.rows.length > 1 && !col.multipleEditing);
    const value = {value: this.getRawColValue(col), disabled};
    const validators = this.getValidators(col);

    return new FormControl(value, validators);
  }

  private getValidators(col): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (col.required) {
      validators.push(Validators.required);
    }
    if (col.type === 'json') {
      validators.push(jsonValidator);
    }
    if (col.typeProps) {
      if (col.typeProps.regExp) {
        validators.push(Validators.pattern(new RegExp(col.typeProps.regExp)));
      }
      if (col.typeProps.min) {
        validators.push(Validators.min(col.typeProps.min));
      }
      if (col.typeProps.max) {
        validators.push(Validators.max(col.typeProps.max));
      }

      if (col.typeProps.maxDateKey) {
        validators.push(maxDateValidator(this.form, this._row, col.typeProps.maxDateKey));
      }

      if (col.typeProps.minDateKey) {
        validators.push(minDateValidator(this.form, this._row, col.typeProps.minDateKey));
      }
    }
    return validators;
  }

  private getRawColValue(col: TableColumnType) {
    let value = this._row[col.prop];
    if (col.type === 'select' && value) {
      const idKey = col.typeProps.linkedIdKey || 'id';
      const nameKey = col.typeProps.linkedKey || 'name';
      const linkedNameKey = col.typeProps.linkedNameKey || 'name';
      if (!col.typeProps.multiple) {
        value = {
          id: this.data.isSearch ? value[idKey] : value,
          [col.typeProps.key]: this.data.isSearch
            ? this._row[nameKey][linkedNameKey]
            : this._row[nameKey],
        };
      } else {
        value = value.map(x => ({
          id: x[idKey],
          [col.typeProps.key]: x[nameKey],
        }));
      }
    }

    return value;
  }

  private getDirtyValues(checkForDirty = true) {
    const dirtyValues = {};
    this.visibleFields.forEach(column => {
      if (column.helper) {
        return;
      }
      const currentControl = this.form.get(column.prop);
      if (
        this.notEmptyValue(currentControl.value) &&
        !currentControl.disabled &&
        (!checkForDirty || currentControl.dirty)
      ) {
        if (column.type === 'select') {
          if (column.typeProps.multiple) {
            dirtyValues[column.prop] = currentControl.value.map(x => x.id);
          } else {
            if (column.typeProps.linkedIdKey) {
              dirtyValues[column.prop] =
                currentControl.value[column.typeProps.linkedIdKey];
            } else {
              dirtyValues[column.prop] = currentControl.value.id;
            }
          }
        } else if (column.type === 'json') {
          dirtyValues[column.prop] = JSON.parse(currentControl.value);
        } else {
          dirtyValues[column.prop] =
            column.type === 'int' || column.type === 'float'
              ? currentControl.value * 1
              : currentControl.value;
        }
      } else if (!currentControl.value && currentControl.dirty) {
        dirtyValues[column.prop] = currentControl.value;
      }
    });
    return dirtyValues;
  }

  private getRequiredCompare({fieldName, compare, target}): boolean {
    const compareFn = {
      gt: (a, b) => a > b,
      lt: (a, b) => a < b,
      eq: (a, b) => a === b,
    };

    const targetValue =
      typeof target === 'number' ? target : this.form.controls[target].value;
    return compareFn[compare](this.form.controls[fieldName].value, targetValue);
  }

  private notEmptyValue(value) {
    return (
      value !== null &&
      value !== '' &&
      typeof value !== 'undefined' &&
      (!(typeof value === 'object' && !Array.isArray(value)) ||
        Object.keys(value).length > 0)
    );
  }

  private disabledIfExistsForm(
    col: TableColumnType,
    control: AbstractControl,
    isHasDefaultValue: boolean,
  ): void {
    control.disable({emitEvent: false});
    control.setValue(isHasDefaultValue ? col.default : null, {
      emitEvent: false,
    });
  }

  private updateDisabledIfExistsForm(
    value: string,
    col: TableColumnType,
    control: AbstractControl,
    isHasDefaultValue: boolean,
  ): void {
    value
      ? this.disabledIfExistsForm(col, control, isHasDefaultValue)
      : control.enable({emitEvent: false});
  }

  private sbsDisabledIfExistsForm(
    col: TableColumnType,
    control: AbstractControl,
    isHasDefaultValue: boolean,
  ): void {
    this.form
      .get(col.disabledIfExists)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(val =>
        this.updateDisabledIfExistsForm(val, col, control, isHasDefaultValue),
      );
  }
}
