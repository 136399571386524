import {Component, OnInit} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {EMPTY, Observable, Subscription} from 'rxjs';

import {PikAuthService} from 'pik-header';
import {NgSelectConfig} from '@ng-select/ng-select';
import {User, UserService} from '@core/services/user.service';

import {environment} from '@env/environment';
import {NewMenuService} from '@core/services/menu.service';
import {LongTasksHub} from '@core/hubs/long-tasks.hub';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: User;
  access: Observable<boolean>;
  isAuthorized: boolean;
  version: string;

  sidePanelOpen: boolean;

  currentLocation: string;
  routerListener: Subscription;

  constructor(
    public $auth: PikAuthService,
    private $user: UserService,
    private selectConfig: NgSelectConfig,
    private $router: Router,
    private $menu: NewMenuService,
    private $longTasksHub: LongTasksHub, // private $notifications: NotificationsService,
  ) {
    this.$auth.authCallbackIfRequired();
    this.selectConfig.notFoundText = 'Ничего не найдено';
    this.selectConfig.loadingText = 'Загрузка...';
    this.selectConfig.typeToSearchText = 'Начните печатать для поиска';
    this.currentLocation = location.host;
    this.version = environment.version;
  }

  ngOnInit() {
    // const snack = this.$notifications.showLoader('Завершаем авторизацию');
    this.$auth.isAuthenticated$.subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
      if (isAuthorized) {
        this.$user
          .getCurrent()
          .pipe(
            catchError(() => {
              this.$router.navigate(['error-user']);
              return EMPTY;
            }),
          )
          .subscribe(user => {
            this.access = this.$menu.hasAccess();
            if (!user) {
              this.$router.navigate(['error-access']);
            } else {
              this.currentUser = user;
              this.initGA();
              this.$longTasksHub.init();
            }
            // snack.dismiss();
          });
      } else {
        console.log('Неавторизованный пользователь. Перенаправление...');
      }
    });
  }

  private initGA() {
    if (!this.routerListener && environment.level === 'prod') {
      const currentLogin = this.currentUser.login
        ? this.currentUser.login
        : 'unauth user';
      (<any>window).ga('create', 'UA-118300756-30', 'auto');
      (<any>window).ga('set', 'userId', currentLogin);
      (<any>window).ga('set', 'dimension1', currentLogin);
      (<any>window).ga('send', {
        hitType: 'pageview',
        page: location.href,
        cd1: currentLogin,
        title: 'Главная',
      });
      this.routerListener = this.$router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          (<any>window).ga('send', {
            hitType: 'pageview',
            page: event.urlAfterRedirects,
            cd1: currentLogin,
            title: event.url,
          });
        }
      });
    }
  }
}
