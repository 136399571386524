<div class="manual-backdrop" (click)="dismiss()"></div>
<h4 *ngIf="!data.isSearch" class="form__header">
  {{ isEditMode || isCopyMode ? "Изменить" : "Создать" }} запись
  <div class="form__dismiss" (click)="dismiss()">
    <mat-icon>clear</mat-icon>
  </div>
</h4>
<h4 *ngIf="data.isSearch" class="form__header">
  Расширенный поиск
  <div class="form__dismiss" (click)="dismiss()">
    <mat-icon>clear</mat-icon>
  </div>
</h4>
<div class="form__body" [formGroup]="form">
  <div *ngIf="isDuplicate" class="form-error">
    <div class="form-error__caption">Запись уже существует.</div>
    <div class="from-error__actions">
      <button
        *ngIf="data.copy"
        mat-raised-button
        color="primary"
        class="form__action"
        (click)="save(true)"
      >
        Отредактировать запись
      </button>
      <button mat-raised-button class="form__action" (click)="dismiss()">
        Отменить изменения
      </button>
    </div>
  </div>
  <ng-container *ngFor="let column of visibleFields; let index = index">
    <ng-container
      *ngIf="index > 1 && column.type == 'text' && visibleFields[index - 1].type != 'text'"
    >
      <div class="form__input-wrapper"></div>
      <div class="form__input-wrapper"></div>
      <div class="form__input-wrapper"></div>
    </ng-container>
    <div
      class="form__input-wrapper"
      [ngClass]="{
        'form__input-wrapper--full-long': column.type == 'text' || column.type == 'json'
      }"
    >
      <ng-container [ngSwitch]="column.type">
        <ng-container *ngSwitchCase="'select'">
          <app-custom-select
            [formControlName]="column.prop"
            [placeholder]="column.label"
            [search]="column.typeProps.search"
            [startSearchAtChar]="column.typeProps.startSearchAtChar || 3"
            [multiple]="column.typeProps.multiple"
            [key]="column.typeProps.key"
            [items]="column.typeProps.items"
            [sortBy]="column.typeProps.sortBy"
            [extra]="column.typeProps.extra"
            [filter]="column.typeProps.filter$ | async"
            [columns]="column.typeProps.columns"
            [idKey]="column.typeProps.idKey"
            [withEntity]="!!column.typeProps.entityFilter"
            [entityName]="column.typeProps.entityName"
            [required]="column.required"
            [itemsApiPrefix]="column.typeProps.apiPrefix || data.config.apiPrefix"
            [itemsSystemName]="column.typeProps.systemName || data.systemName"
            [clearable]="column.clearable"
            (change)="onSelectChange(column, $event)"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-custom-select>
        </ng-container>
        <ng-container *ngSwitchCase="'boolean'">
          <ng-select
            class="app-custom-select"
            [ngClass]="{ 'app-custom-select--required': column.required }"
            [formControlName]="column.prop"
            [placeholder]="column.label"
            [searchable]="false"
            [items]="[
              { value: true, label: 'Да' },
              { value: false, label: 'Нет' }
            ]"
            [bindValue]="'value'"
            [clearable]="!column.required"
            [required]="column.required"
          ></ng-select>
        </ng-container>
        <ng-container *ngSwitchCase="'string'">
          <app-input
            class="cell-input"
            [appInputFilter]="column.typeProps?.charsFilter"
            [placeholder]="column.label"
            [formControlName]="column.prop"
            [required]="column.required"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-input>
        </ng-container>
        <ng-container *ngSwitchCase="'int'">
          <app-input
            type="number"
            class="cell-input"
            [placeholder]="column.label"
            [formControlName]="column.prop"
            [required]="column.required"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-input>
        </ng-container>
        <ng-container *ngSwitchCase="'float'">
          <app-input
            type="number"
            class="cell-input"
            [placeholder]="column.label"
            [formControlName]="column.prop"
            [required]="column.required"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-input>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          <app-date-input
            class="cell-input"
            [placeholder]="column.label"
            [localTime]="!!column.typeProps && !!column.typeProps.localTime"
            [formControlName]="column.prop"
            [required]="column.required"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-date-input>
        </ng-container>
        <ng-container *ngSwitchCase="'color'">
          <div
            class="cell-prefix cell-prefix--color"
            [style.background]="form.controls[column.prop].value"
            [colorPicker]="form.controls[column.prop].value"
            [cpOutputFormat]="'hex'"
            [cpAlphaChannel]="'disabled'"
            [cpPresetColors]="['#FF0000', '#00B345', '#00B0F0']"
            (colorPickerChange)="onChangeColor(column.prop, $event)"
          ></div>
          <app-input
            class="cell-input"
            [placeholder]="column.label"
            [formControlName]="column.prop"
            [required]="column.required"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-input>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <app-text-area
            class="cell-input"
            [appInputFilter]="column.typeProps?.charsFilter"
            [placeholder]="column.label"
            [formControlName]="column.prop"
            [required]="column.required"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-text-area>
        </ng-container>

        <ng-container *ngSwitchCase="'json'">
          <app-text-area
            class="cell-input"
            [placeholder]="column.label"
            [formControlName]="column.prop"
            [required]="column.required"
          >
            <ng-container
              *ngTemplateOutlet="errors; context: { controlName: column.prop }"
            ></ng-container>
          </app-text-area>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="data.config.columns.length > 4">
    <div class="form__input-wrapper"></div>
    <div class="form__input-wrapper"></div>
    <div class="form__input-wrapper"></div>
  </ng-container>
  <div
    *ngIf="!data.copy && ((data.config.files && data.rows.length == 1) || data.config.icons)"
    class="form__uploads"
  >
    <app-file-upload
      *ngFor="let file of data.config.files"
      class="form__uploads-item"
      [config]="file"
      [value]="_row[file.prop]"
      [rowId]="_row.id"
      [preview]="file.preview"
      [previewLink]="file.previewLink ? _row[file.previewLink] : null"
      [systemName]="data.systemName"
      [fileDropAccess]="fileDropAccess"
    ></app-file-upload>
    <div
      *ngFor="let icon of data.config.icons"
      class="form__upload file-upload form__uploads-item"
    >
      <div
        *ngIf="icon.preview && (_row[icon.previewLink] || iconPreview)"
        class="file-upload__preview"
      >
        <img
          alt="config.label"
          class="file-upload__preview-image"
          [src]="iconPreview || (icon.previewLink ? _row[icon.previewLink] : null)"
        />
      </div>
      <a
        href="{{ _row[icon.prop] }}"
        target="_blank"
        class="file-upload__current"
      >
        {{ _row[icon.prop] }}
      </a>
      <div class="file-upload__control">
        <div class="file-upload__label">{{ icon.label }}</div>
        <label
          *ngIf="!icon.disabled"
          class="btn btn--blue file-upload__button"
          [ngClass]="{ 'btn--disabled': uploading }"
        >
          <mat-icon>attachment</mat-icon>
          &nbsp;
          <span>Загрузить файл</span>
          <input
            #iconUpload=""
            type="file"
            accept="image/*"
            class="file-upload__input"
            (change)="onIconAdd(iconUpload, icon)"
          />
        </label>
      </div>
    </div>
    <div class="form__uploads-item"></div>
    <div class="form__uploads-item"></div>
    <div class="form__uploads-item"></div>
  </div>
</div>
<div class="form__footer">
  <div class="form__actions">
    <button
      *ngIf="data.isSearch"
      mat-raised-button
      class="form__action form__action--left"
      (click)="clearFilters()"
    >
      Очистить
    </button>
    <button
      mat-raised-button
      class="form__action form__action--right"
      (click)="dismiss()"
    >
      Отменить
    </button>
    <button
      *ngIf="!data.isSearch"
      mat-raised-button
      color="primary"
      class="form__action"
      [disabled]="form.invalid || (data.config.icons && !fd.has(data.config.icons[0].fileKey))"
      (click)="save()"
    >
      {{ data.rows.length === 0 || data.copy ? "Создать" : "Сохранить" }}
    </button>
    <button
      *ngIf="data.isSearch"
      mat-raised-button
      color="primary"
      class="form__action"
      (click)="sendFilters()"
    >
      Обновить
    </button>
  </div>
</div>

<ng-template #errors let-controlName="controlName">
  <div
    *ngIf="form.controls[controlName].dirty && form.controls[controlName].errors?.required"
    class="input-hint input-hint--alert"
  >
    Это поле обязательное
  </div>
  <div
    *ngIf="form.controls[controlName].dirty && form.controls[controlName].errors?.pattern"
    class="input-hint input-hint--alert"
  >
    Значение не подходит по формату
  </div>
  <div
    *ngIf="form.controls[controlName].dirty && form.controls[controlName].errors?.min"
    class="input-hint input-hint--alert"
  >
    Значение меньше минимального
  </div>
  <div
    *ngIf="form.controls[controlName].dirty && form.controls[controlName].errors?.max"
    class="input-hint input-hint--alert"
  >
    Значение больше максимального
  </div>
  <div
    *ngIf="form.controls[controlName].dirty && form.controls[controlName].errors?.maxDateKey"
    class="input-hint input-hint--alert"
  >
    Дата начала не может превышать дату окончания.
  </div>
  <div
    *ngIf="form.controls[controlName].dirty && form.controls[controlName].errors?.minDateKey"
    class="input-hint input-hint--alert"
  >
    Дата окончания не может быть раньше даты начала.
  </div>
  <div
    *ngIf="form.controls[controlName].dirty && form.controls[controlName].errors?.jsonInvalid"
    class="input-hint input-hint--alert"
  >
    Ошибки в json
  </div>
</ng-template>

<div *ngIf="confirmationVisible" class="confirmation">
  <div class="confirmation__body">
    <div class="confirmation__text">
      Вы действительно хотите выйти из карточки?
      <br />
      Введенные данные не сохранятся.
    </div>
    <div class="confirmation__actions">
      <button
        mat-raised-button
        class="form__action"
        (click)="confirmedDismiss(false)"
      >
        Нет
      </button>
      <button
        mat-raised-button
        color="primary"
        class="form__action"
        (click)="confirmedDismiss(true)"
      >
        Да
      </button>
    </div>
  </div>
</div>
