import {PikAuthConfig, PikEnvironment} from 'pik-header';

export const environment = {
  api: 'https://build02pik.picompany.ru:37782',
  appName: 'bopadmin',
  assetsUrl: '',
  currentUser: null,
  hub: 'https://build02pik.picompany.ru:37780',
  level: 'test',
  needAuth: true,
  pikEnvironment: PikEnvironment.test,
  production: false,
  userCardsUrl: 'https://testhome.pik.ru/employees/',
  version: require('../../package.json').version,
  auth: {
    allowExternal: true,
    authEnvironment: PikEnvironment.test,
    clientId: 'backoffice_spa',
    showHeaderForExternal: true,
    scope:
      'openid profile email bop_api home_api pdl_api bank_api wiki_api photo_api partner_api sgp_api navigation_api allow_external',
  } as PikAuthConfig,
};
